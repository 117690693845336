import React from 'react'
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import Realite from '../../assets/img/realite.png';
import Sato from '../../assets/img/sato1.png';
import Sick from '../../assets/img/sick.png';
import Entrust from '../../assets/img/entrust.png';
import Acronis from '../../assets/img/acronis.png';
import Cynet from '../../assets/img/cynet.png';
import Synology from '../../assets/img/synology.png';
import Analytics from '../../assets/img/analytics.png';
import Arista from '../../assets/img/arista.png';
import Dell from '../../assets/img/arista.png';
import Room from '../../assets/img/room.png';
import Fujitsu from '../../assets/img/fujitsu.png';
import Impinj from '../../assets/img/impinj.png';
import CypherDog from '../../assets/img/cypher-dog.png';
import Trackit from '../../assets/img/trackit.png';
import Reblaze from '../../assets/img/reblaze.png';

const partners = [
  {
    logos: [Realite, Sato, Entrust, Acronis]
  },
  {
    logos: [Cynet, Synology, Analytics, Arista]
  },
  {
    logos: [Dell, Fujitsu, Impinj, Room]
  },
  {
    logos: [CypherDog,Sick,Trackit,Reblaze]
  }
];


export default function PartnerSlider() {
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      slidesPerView: 1
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 2000)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]
  );

  return (
    <div ref={sliderRef} className="keen-slider my-8">
      {partners.map((pt, index) => (
        <div className="keen-slider__slide grid lg:grid-cols-4 my-4 sm:mx-auto" key={index}>
          {pt.logos.map((lg, i) => (
            <div className="p-4 bg-white flex justify-center items-center">
              <img key={i} src={lg} alt="partner logo" className="sm:w-32" />
            </div>
          ))}
        </div>
      ))}

    </div>
  )
}
