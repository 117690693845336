import React from 'react'
import BackgroundImg from '../../assets/img/contact-bg.png';
import Pattern from '../../assets/img/pattern.png';
import { FaPhoneAlt, FaEnvelope, FaMapPin, FaClock } from "react-icons/fa";

export default function Contact() {
  return (
    <>
      <section className="relative h-[60vh] w-full bg-cover bg-center" style={{
        backgroundImage: `url(${BackgroundImg})`,
        borderBottom: '6px solid',
        borderImageSource: `url(${Pattern})`,
        borderImageSlice: 20
      }}>
        <h1 className="absolute sm:bottom-20 lg:bottom-40 text-white font-bold sm:text-3xl lg:text-5xl lg:w-2/3 sm:left-4 lg:left-20 z-10">Get in Touch with us</h1>
        <div className="absolute top-0 bottom-0 right-0 left-0 bg-secondary/60" />
      </section>
      <section className="grid lg:grid-cols-2 mt-4">
        <div className="lg:px-20 sm:px-2 sm:my-10 lg:my-20">
          <h1 className="lg:my-10 sm:my-5 font-bold text-3xl">Contact us</h1>
          <div className='grid grid-cols-2 py-4 lg:gap-10  sm:gap-4 text-center'>
            <div className="flex items-center hover:scale-105">
              <FaPhoneAlt className="sm:text-sm lg:text-lg sm:w-5" />
              <p className='lg:pl-4 sm:pl-2 text-sm text-start font-thin'> Contact Phone: <br />
              <span className='text-xs font-normal'>
                +254 750 211117
                </span>
              </p>
            </div>
            <div className="flex items-center hover:scale-105">
              <FaEnvelope className="sm:text-sm lg:text-lg w-5" />
              <p className='pl-4 text-sm text-start font-thin'> Contact Mail: <br />
              <span className='text-xs font-normal'>
                sales@eITs.co.ke
                </span>
              </p>
            </div>
            <div className="flex items-center hover:scale-105">
              <FaMapPin className="sm:text-sm lg:text-lg w-6" />
              <p className='pl-4 text-sm text-start font-thin'> Our Address: <br />
              <span className='text-xs font-normal'>
                Victor Otieno & Associates Building,Joseph Kangethe Road, Off Ngong Road.
                </span>
              </p>
            </div>
            <div className="flex items-center hover:scale-105">
              <FaClock className="sm:text-sm lg:text-lg w-5" />
              <p className='pl-4 text-sm text-start font-thin'> Our Working Hours: <br />
                <span className='text-xs font-normal'>
                  Mon -  Fri<br />
                  9:00 AM - 5:00 PM <br/>
                  Closed on Sat, Sun & Public Holidays.
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className='bg-secondary text-white lg:px-6 sm:px-2 py-20 rounded mx-auto lg:w-10/12 sm:w-full'>
          <h1 className="my-8 font-bold text-3xl text-center">Send us a message</h1>
          <form className='lg:w-2/3 mx-auto'>
            <div className="bg-white my-2 rounded p-2 w-full">
              <input type="text" placeholder='Enter your name' className="focus:outline-none text-black w-full" />
            </div>
            <div className="bg-white my-2 rounded p-2 w-full focus:outline-none">
              <input type="email" placeholder='Enter your email' className="focus:outline-none text-black w-full" />
            </div>
            <div className="bg-white my-2 rounded p-2 w-full focus:outline-none ">
              <input type="text" placeholder='Enter your phone number' className="focus:outline-none text-black w-full" />
            </div>
            <div className="bg-white my-2 rounded p-2 w-full focus:outline-none ">
              <textarea rows={5} placeholder='Enter your message here' className="focus:outline-none text-black w-full" />
            </div>
            <div className="bg-primary w-1/2 text-center my-4 rounded p-2 focus:outline-none cursor-pointer hover:scale-105">
              <input type="submit" value='SEND' />
            </div>
          </form>
        </div>

      </section>
      <section className="mt-20 sm:h-[300px] lg:h-[400px]">
        <iframe className="h-full w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7857509001565!2d36.78439197592596!3d-1.303557135647224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11c72786ffed%3A0x6952b9f2a35e2800!2senterprise%20IT%20solutions%20ltd!5e0!3m2!1sen!2ske!4v1687265509700!5m2!1sen!2ske" style={{ border: 0 }} allowFullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Enterprise IT solutions offices"></iframe>
      </section>
    </>
  )
}
