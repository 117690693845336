import React from 'react'
import ImageSlider from '../../components/ImageSlider';
import Slide1 from '../../assets/img/rfid.png';
import Slide2 from '../../assets/img/scada.png';
import Slide3 from '../../assets/img/Suprema.png';
import Slide4 from '../../assets/img/analytic.png';
import Background from '../../assets/img/we-are.png';
import { FaArrowRight } from 'react-icons/fa';
import BackgroundPattern from '../../assets/img/bgpattern.png';
import Bty from '../../assets/img/bty.png';
import Bty2 from '../../assets/img/bty-1.png';
import SupplyChainCover from '../../assets/img/supply-chain.png';
import AgricultureCover from '../../assets/img/agriculture.png';
import HealthcareCover from '../../assets/img/healthcare.png';
import FinanceCover from '../../assets/img/finance.png';
import PartnerSlider from '../../components/PartnerSlider';
import { RoutePaths } from '../../utils/constant';

const slides = [
  {
    image: Slide1,
    title: 'Data Collection | Acquisition',
    bullets: [
        'AUTO-ID SOLUTION ( IOT , IIOT, RFID , BARCODES, LABELS)',
        'INDUSTRIAL & LOGISTICS AUTOMATION SOLUTIONS (SENSORS, IIOT,PLC)',
        'TRANSFORMING PAPER-BASED INFORMATION INTO ACTIONABLE DATA'
    ],
  },
  {
    image: Slide2,
    title: 'Data Storage | Disaster Recovery',
    bullets: [
        'ON-PREMISE & CLOUD',
        'PRODUCTION & TEST FAIL OVER'
    ],
  },
  {
    image: Slide3,
    title: 'Data Security & Protection',
    bullets: [
        'DLP-DATA LOSS PREVENTION',
        'ADVANCED EMAIL SECURITY',
        'PATCH MANAGEMENT ETC'
    ],
  },
  {
    image: Slide4,
    title: 'Data Analytics & Professional Services',
    bullets: [
        'DESCRIPTIVE, DIAGNOSTIC, PREDICTIVE PRESCRIPTIVE'
    ],
  }
];
const useCases = [
  {
    title: 'Supply Chain',
    image: SupplyChainCover
  },
  {
    title: 'Agriculture',
    image: AgricultureCover
  },
  {
    title: 'Healthcare',
    image: HealthcareCover
  },
  {
    title: 'Finance',
    image: FinanceCover
  }
];

export default function Home() {
  return (
    <>
      <ImageSlider slides={slides} />
      <section className="lg:my-24 sm:my-6 flex items center mx-auto sm:flex-col lg:flex-row">
        <div className="lg:w-1/2 lg:h-[580px] sm:h-[300px] sm:w-full" style={{ backgroundImage: `url(${Background})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
        <div className="lg:py-4 sm:px-2 text-start sm:w-full lg:w-2/5 lg:pl-10">
          <h1 className="sm:text-4xl lg:text-5xl font-bold mb-6">Who we are</h1>
          <p className="sm:text-md lg:text-xl my-6 font-thin lg:text-justify sm:tracking-tighter">
            We are a Pan African technology solutions provider
            based in Nairobi, Kenya that leverages on the power of
            data to provide solutions that help Organizations in the
            Digital Transformation Journey!
          </p>
          <p className="sm:text-md lg:text-xl my-6 font-thin lg:text-justify sm:tracking-tighter">
            We do this by providing them with the infrastructure,
            systems, and expertise required for seamless
            implementation.
          </p>
          <p className="sm:text-md lg:text-xl my-6 font-thin lg:text-justify sm:tracking-tighter">
            We work in four thematic areas which are data
            acquisition, data analysis, data storage and data security
            in which we walk with our clients to design solutions
            that are tailored-made to meet their specific needs
            hence optimize their functioning.
          </p>
          <a href={RoutePaths.CONTACT} className="lg:my-8 lg:py-3 sm:py-2 sm:px-2 lg:px-6 text-white bg-secondary font-medium hover:scale-105 rounded flex items-center justify-center text-lg lg:w-[33%] sm:w-2/3 sm:mx-auto lg:ml-0">
            Get In Touch
            <FaArrowRight className="ml-2" />
          </a>
        </div>
      </section>
      <section className="sm:my-0 lg:my-14 bg-tertiary rounded text-white flex sm:flex-col lg:flex-row items-center justify-around  sm:w-full lg:w-10/12 lg:mx-auto sm:p-4 lg:p-16">
        <div className="text-center sm:mt-4">
          <h1 className="text-7xl font-bold mb-2">100+</h1>
          <h2 className='font-thin text-xl'>Clients</h2>
        </div>
        <div className="text-center sm:mt-10">
          <h1 className="text-7xl font-bold mb-2">30+</h1>
          <h2 className='font-thin text-xl'>Projects</h2>
        </div>
        <div className="text-center sm:mt-10">
          <h1 className="text-7xl font-bold mb-2">5</h1>
          <h2 className='font-thin text-xl'>Awards</h2>
        </div>
      </section>
      <section className="text-white py-10 bg-fixed" style={{ backgroundImage: `url(${BackgroundPattern})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
        <div className="sm:mb-10 sm:text-center lg:py-10">
          <h1 className='lg:text-5xl sm:text-3xl font-bold'>What we do</h1>
        </div>
        <div className="flex sm:flex-col lg:flex-row mx-auto sm:w-full sm:items-center lg:w-9/12 lg:mb-10 sm:mb-2">
          <div className="border-r-8 sm:w-11/12 lg:w-[64%] rounded border-primary py-4 flex justify-center flex-col">
            <h1 className="mb-6 font-bold text-3xl">Solutions & Focus<br />
              Areas</h1>
            <p className="font-thin mb-2 pr-2 sm:tracking-tighter lg:text-justify">
              We provide comprehensive solutions to our
              clients by creating, designing and implementing
              comprehensive solutions that aid to improve
              business processes, efficiencies and ultimately
              their bottom lines. With our in-house technical
              expertise, our understanding of business
              environment and partners we offer the state of
              the art solutions in four thematic area.
            </p>
          </div>
          <div className="lg:px-6 sm:px-2 sm:mt-6">
            <h1>Solutions for Data Collection & Analysis & Big Data</h1>
            <div className="grid lg:grid-cols-2 sm:grid-cols-1 lg:my-4 sm:gap-2 lg:gap-4">
              <div className="bg-white px-6 py-10 rounded text-secondary relative hover:scale-105 hover:bg-tertiary hover:text-white transition-colors delay-75 duration-300 ease-in-out sm:h-[150px] lg:h-[200px]">
                <h1 className="font-bold my-6 sm:text-lg lg:text-2xl">DATA COLLECTION</h1>
                <a href={`/solutions/${'DATA COLLECTION'}`} className="float-right w-[30px] h-[30px] drop-shadow-xl bg-primary text-white hover:scale-105 p-2 rounded-full flex justify-center items-center absolute bottom-6 right-10">
                  <FaArrowRight />
                </a>
              </div>
              <div className="bg-white px-4 py-10 rounded text-secondary relative hover:scale-105 hover:bg-tertiary hover:text-white transition-colors delay-75 duration-300 ease-in-out sm:h-[150px] lg:h-[200px]">
                <h1 className="font-bold my-6 sm:text-lg lg:text-2xl">DATA BACKUP,STORAGE AND DISASTER RECOVERY</h1>
                <a href={`/solutions/${'DATA BACKUP,STORAGE AND DISASTER RECOVERY'}`} className="float-right w-[30px] h-[30px] drop-shadow-xl bg-primary text-white hover:scale-105 p-2 rounded-full flex justify-center items-center absolute bottom-6 right-10">
                  <FaArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-1 lg:grid-cols-3 sm:w-full sm:px-2 lg:mx-auto lg:w-9/12 sm:gap-2 lg:gap-4 lg:pr-6">
          <div className="bg-white px-4 py-10 rounded text-secondary relative hover:scale-105 hover:bg-tertiary hover:text-white transition-colors delay-75 duration-300 ease-in-out lg:h-[200px] sm:h-[150px]">
            <h1 className="font-bold my-6 sm:text-lg lg:text-2xl">CYBER SECURITY AND DATA SECURITY</h1>
            <a href={`/solutions/${'CYBER SECURITY AND DATA SECURITY'}`} className="float-right w-[30px] h-[30px] drop-shadow-xl bg-primary text-white hover:scale-105 p-2 rounded-full flex justify-center items-center absolute bottom-6 right-10">
              <FaArrowRight />
            </a>
          </div>
          <div className="bg-white px-4 py-10 rounded text-secondary relative hover:scale-105 hover:bg-tertiary hover:text-white transition-colors delay-75 duration-300 ease-in-out lg:h-[200px] sm:h-[150px]">
            <h1 className="font-bold my-6 sm:text-lg lg:text-2xl">CUTTING EDGE BIG DATA AND ANALYTICS
              SERVICE</h1>
            <a href={`/solutions/${'CUTTING EDGE BIG DATA AND ANALYTICS'}`} className="float-right w-[30px] h-[30px] drop-shadow-xl bg-primary text-white hover:scale-105 p-2 rounded-full flex justify-center items-center absolute bottom-6 right-10">
              <FaArrowRight />
            </a>
          </div>
          <div className="bg-white px-4 py-10 rounded text-secondary relative hover:scale-105 hover:bg-tertiary hover:text-white transition-colors delay-75 duration-300 ease-in-out lg:h-[200px] sm:h-[150px]">
            <h1 className="font-bold my-6 sm:text-lg lg:text-2xl uppercase">PROFESSIONAL & CONSULTANCY SERVICES</h1>
            <a href={`/solutions/${'PROFESSIONAL & CONSULTANCY SERVICES'}`} className="float-right w-[30px] h-[30px] drop-shadow-xl bg-primary text-white hover:scale-105 p-2 rounded-full flex justify-center items-center absolute bottom-6 right-10">
              <FaArrowRight />
            </a>
          </div>
        </div>
      </section>
      <section>
        <div style={{ backgroundImage: `url(${Bty})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} className="py-10 text-center mx-auto w-10/12">
          <h1 className="sm:text-3xl lg:text-5xl font-bold text-secondary mb-10">Sector Specific Use Cases</h1>
          <p className="sm:text-sm lg:text-lg sm:w-11/12 lg:w-10/12 mx-auto">We work in all sectors to provide internal solutions that help with simple tracking financial solutions, track accounting, supply chain, HR and Finance.</p>
        </div>
        <div className="mt-10 sm:w-11/12 lg:w-10/12 mx-auto grid sm:grid-cols-1 lg:grid-cols-2 sm:gap-2 lg:gap-4">
          {useCases.map(c => (
            <div style={{ backgroundImage: `url(${c.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundBlendMode: 'multiply' }} key={c.title} className="rounded-2xl flex items-center bg-contain bg-no-repeat duration-300 delay-75 ease-in-out justify-center sm:h-[300px] lg:h-[600px] bg-secondary/60 relative hover:scale-95 hover:text-6xl">
              {/* <div className="absolute top-0 bottom-0 sm:right-0 lg:right-10 left-0 bg-secondary/60 rounded-2xl" /> */}
              <h1 className="text-white text-center font-bold text-5xl z-20">{c.title}</h1>
            </div>
          ))}
        </div>
      </section>
      <section className="mt-10 text-center">
        <div style={{ backgroundImage: `url(${Bty2})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} className="py-10 text-center mx-auto w-10/12">
          <h1 className="sm:text-3xl lg:text-5xl font-bold text-secondary mb-10">Our Partner Network</h1>
          <p className="sm:text-sm lg:text-lg  sm:w-11/12 lg:w-10/12 mx-auto ">We are authorised to distribute software and hardware from the following companies:</p>
        </div>
      </section>
      <section>
        <PartnerSlider />
      </section>
    </>
  )
}
