import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Header from '../components/Header';
import {RoutePaths} from '../utils/constant';
import Footer from '../components/Footer';
import Solution from '../pages/Solution';

export default function Router() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path={RoutePaths.HOME} element={<Home />} />
        <Route path={RoutePaths.ABOUT} element={<About />} />
        <Route path={RoutePaths.CONTACT} element={<Contact />} />
        <Route path={RoutePaths.SOLUTION} element={<Solution />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  )
}
