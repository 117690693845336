import React from 'react';

import './swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination } from 'swiper';
// import { FaArrowRight } from 'react-icons/fa';
// import { RoutePaths } from '../../utils/constant';


const ImageSlider = ({ slides }) => {

    return (
        <Swiper
            style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#25AEE1",
                "--swiper-pagination-bullet-inactive-color": "#ffff",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                "--swiper-pagination-bullet-size": "10px",
                "--swiper-pagination-bullet-horizontal-gap": "6px"
            }}
            spaceBetween={5}
            centeredSlides={true}
            speed={600}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={false}
            modules={[EffectFade, Autoplay, Pagination]}
            effect={"fade"}
            className="mySwiper p-0"
        >
            {slides.map((item, index) => (
                <SwiperSlide
                    key={index}
                    className=""
                    style={{ backgroundImage: `url('${item.image}')`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                >
                    <div className="absolute top-0 left-0 bottom-0 right-0 bg-overlay/70" />
                    <div className="absolute sm:bottom-10 sm:p-2 lg:bottom-24 sm:left-0 lg:left-24 flex flex-col text-start text-white bg-fixed sm:w-full lg:w-2/3" data-swiper-parallax="-300">
                        <h1 className="my-4 sm:text-3xl lg:text-6xl  font-bold">{item.title}</h1>
                        <ul className='my-2 pl-2'>
                            {item.bullets.map(bl => (
                                <li className='p-2 font-thin flex items-center'>
                                    <svg className='mr-2' width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.74365 8.64502L3.10303 15.2856C2.64404 15.7446 1.90186 15.7446 1.44775 15.2856L0.344238 14.1821C-0.114746 13.7231 -0.114746 12.981 0.344238 12.5269L5.05127 7.81982L0.344238 3.11279C-0.114746 2.65381 -0.114746 1.91162 0.344238 1.45752L1.44287 0.344238C1.90186 -0.114746 2.64404 -0.114746 3.09814 0.344238L9.73877 6.98486C10.2026 7.44385 10.2026 8.18604 9.74365 8.64502ZM19.1187 6.98486L12.478 0.344238C12.019 -0.114746 11.2769 -0.114746 10.8228 0.344238L9.71924 1.44775C9.26025 1.90674 9.26025 2.64893 9.71924 3.10303L14.4263 7.81006L9.71924 12.5171C9.26025 12.9761 9.26025 13.7183 9.71924 14.1724L10.8228 15.2759C11.2817 15.7349 12.0239 15.7349 12.478 15.2759L19.1187 8.63525C19.5776 8.18603 19.5776 7.44385 19.1187 6.98486Z" fill="white" />
                                    </svg>
                                    {bl}
                                </li>
                            ))}
                        </ul>
                        {/* <p className="lg:my-4 sm:my-2 sm:text-sm lg:text-3xl font-thin text-justify"></p> */}
                        {/* <a href={RoutePaths.CONTACT} className="my-8 py-3 px-6 text-white bg-primary font-medium capitalise hover:scale-105 rounded flex items-center justify-center text-lg sm:w-2/3 lg:w-[30%]">
                            Get In Touch
                            <FaArrowRight className="ml-2" />
                        </a> */}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default ImageSlider;