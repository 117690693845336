import React from 'react';
import { FaPhoneSquare, FaEnvelope, FaMapMarker, FaFacebookSquare, FaTwitterSquare, FaLinkedin } from 'react-icons/fa';
import './style.css';
import {RoutePaths} from '../../utils/constant';

export default function Footer() {
    return (
        <div className="bg-black flex flex-col lg:px-20 text-white">
            <div className="grid sm:grid-cols-1 sm:gap-4 sm:px-4 lg:grid-cols-3  lg:px-0 border-b-2 border-primary mt-10 py-4 ">
                <p className="text-lg w-1/2 font-bold">For Data solutions and IT consulting service</p>
                <div className="">
                    <h1>CALL US</h1>
                    <span className="flex items-center text-primary mt-4"><FaPhoneSquare /> <span className="text-white ml-2 text-sm">+254 750 211117</span></span>
                </div>
                <div className="">
                    <h1>MAIL US</h1>
                    <span className="flex items-center text-primary mt-4"><FaEnvelope /> <span className="text-white ml-2 text-sm">info@eits.co.ke</span></span>
                </div>
            </div>
            <div className="grid sm:grid-cols-1 sm:gap-4 sm:px-4 lg:px-0 lg:grid-cols-3 justify-around my-5 items-center">
                <div className='w-1/2'>
                    <span className="flex items-start">
                        <span className='p-[2px] rounded bg-primary mr-2'>
                        <FaMapMarker className='text-black text-xs'/>
                        </span>
                    <p>Victor Otieno and Associates Building,
                        Joseph Kangethe Rd, Nairobi, Kenya</p>
                    </span>
                    <span className="flex items-center mt-8 text-primary text-lg">
                        <a href="/" className="hover:scale-110">
                            <FaFacebookSquare className='mr-2' />
                            </a>
                        <a href="/" className="hover:scale-110">
                            <FaTwitterSquare className='mr-2' />
                            </a>
                        <a href="/" className="hover:scale-110">
                            <FaLinkedin className='mr-2' />
                            </a>
                    </span>
                </div>
                <div>
                    <h1>Menu</h1>
                    <ul>
                        <li className="my-2 text-sm hover:text-primary">
                            <a href="/">Home</a></li>
                        <li className="my-2 text-sm hover:text-primary">
                            <a href={RoutePaths.CONTACT}>Contact Us</a></li>
                    </ul>
                </div>
                <div>
                    <h1 className="my-2">Don't miss out, Subscribe to our news letter</h1>
                    <form>
                        <div className="grid grid-cols-2">
                            <input type="email" placeholder="e.g example@gmail.com" className="email text-secondary rounded-ss-sm rounded-es-sm p-2 text-xs" />
                            <input type='submit' value="SUBSCRIBE" className="bg-secondary text-white py-2 hover:cursor-pointer hover:scale-105" />
                        </div>
                    </form>
                </div>
            </div>
            <div className="text-center lg:text-sm mt-10 sm:text-xs sm:w-10/12 sm:mx-auto">
                <p className="">&copy; {new Date().getFullYear()} eITs. All rights reserved. Built & Designed by <a className='text-primary text-xs' href="https://wezaprosoft.com">Weza Prosoft</a></p>
            </div>
        </div>
    )
}
