import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Sato from '../../assets/img/sato1.png';
import Sick from '../../assets/img/sick.png';
import Realiteq from '../../assets/img/realite.png';
import Impinj from '../../assets/img/impinj.png';
import Entrust from '../../assets/img/entrust.png';
import Arista from '../../assets/img/arista.png';
import Acronis from '../../assets/img/acronis.png';
import Cynet from '../../assets/img/cynet.png';
import Dell from '../../assets/img/dell.png';
import Room from '../../assets/img/room.png';
import CypherDog from '../../assets/img/cypher-dog.png';
import Conceal from '../../assets/img/conceal.png';
import Reblaze from '../../assets/img/reblaze.png';
import Powerbi from '../../assets/img/powerbi.png';
import Fudjitsu from '../../assets/img/fujitsu.png';
import Trackit from '../../assets/img/trackit.png';
import Synology from '../../assets/img/synology.png';
import BackgroundImg from '../../assets/img/solutions-bg.png';
import Pattern from '../../assets/img/pattern.png';
import Challenge from '../../assets/img/challenge.png';
import Ptbg from '../../assets/img/pattern-bg.png';
import { FaArrowRight } from 'react-icons/fa';
import { RoutePaths } from '../../utils/constant';
import { FaChevronRight} from 'react-icons/fa';

const solutions = [
    {
        title: 'DATA COLLECTION',
        challenge:'Companies without proper software solutions often rely on manual data entry, which is time-consuming and error-prone. It can lead to data inconsistencies, duplicate entries, and inaccuracies',
        solutions: [
            'Our solution streamlines data collection processes, eliminating the need for manual entry. By leveraging automated data capture and integration capabilities, we ensure accurate and consistent data across systems. This saves time, reduces errors, and provides companies with reliable data for informed decision-making.'
        ],
        brands: [Sato,Sick ,Realiteq, Impinj,Trackit, Entrust,Arista,Room, Fudjitsu],
        cover: ''
    },
    {
        title: 'DATA BACKUP,STORAGE AND DISASTER RECOVERY',
        challenge:'Without proper data backup and storage solutions, companies face the risk of permanent data loss due to hardware failures, software corruption, accidental deletions, or natural disasters. This can result in the loss of critical business information, customer records, transaction history, and other valuable data',
        solutions: [
            'With our robust data backup and storage solutions, companies can safeguard their valuable information. We implement redundant storage systems and regular backups to protect against data loss. In the event of hardware failures, software issues, or natural disasters, our disaster recovery protocols ensure swift data restoration, minimizing downtime and ensuring business continuity.'
        ],
        brands: [Acronis,Dell,Synology],
        cover: ''
    },
    {
        title: 'CYBER SECURITY AND DATA SECURITY',
        challenge:'Without adequate cybersecurity measures in place, companies are more vulnerable to cyber threats, such as malware, ransomware, phishing attacks, and data breaches. We provide solutions, technologies, and protocols to protect their systems and data effectively.',
        solutions: [
            'Our comprehensive cybersecurity measures fortify company systems against cyber threats. We employ cutting-edge technologies, such as firewalls, encryption, and intrusion detection systems, to prevent unauthorized access, data breaches, and malicious attacks. By implementing robust security protocols and regular vulnerability assessments, we ensure the confidentiality, integrity, and availability of critical data.'
        ],
        brands: [Cynet,CypherDog,Conceal,Reblaze],
        cover: ''
    },
    {
        title: 'CUTTING EDGE BIG DATA AND ANALYTICS',
        challenge:'Without advanced  data analytics solutions, companies struggle to analyze large volumes of data. They may also lack the necessary processes to derive insights from complex data sets, leading to missed opportunities in understanding customer behavior, market trends, and operational efficiencies.',
        solutions: [
            'Our advanced data analytics solutions empower companies to unlock valuable insights from their vast data sets. Using machine learning algorithms and data visualization tools, we help businesses derive actionable intelligence, enabling them to make data-driven decisions. By harnessing the power of big data analytics, companies can uncover hidden patterns, identify trends, and optimize their operations for enhanced productivity and competitiveness.',
        ],
        brands: [Powerbi],
        cover: ''
    },
    {
        title: 'PROFESSIONAL & CONSULTANCY SERVICES',
        challenge:'Companies without professional services struggle with developing and executing strategic plans. Professional services providers offer expertise in areas such as market analysis, competitive intelligence, business process optimization, and strategic decision-making, helping companies navigate complex business landscapes.',
        solutions: [
            "Our professional services team brings extensive industry knowledge and expertise to help companies navigate complex challenges. We offer tailored consultancy services, including market analysis, competitive intelligence, and strategic planning. Our consultants work closely with clients to identify growth opportunities, optimize business processes, and develop strategic roadmaps, ensuring companies stay ahead in today's dynamic business landscape. ",
        ],
        brands: [Sato],
        cover: ''
    },
];


export default function Solution() {
    const [selectedSolutions, setSelectedSolution] = useState(solutions[0]);

    const { id } = useParams();

    const updateCurrentSolution = () => {
        let selected = solutions.filter(item => item.title === id);
        if (selected.length > 0) {
            setSelectedSolution(selected[0]);
        } else {
            setSelectedSolution(solutions[0]);
        }
    }

    useEffect(() => {
        updateCurrentSolution();
    });

    return (
        <>

            <div className="relative h-[60vh] w-full bg-cover bg-center" style={{
                backgroundImage: `url(${BackgroundImg})`,
                borderBottom: '6px solid',
                borderImageSource: `url(${Pattern})`,
                borderImageSlice: 20
            }}>
                <h1 className="absolute sm:bottom-10 sm:px-2 lg:bottom-40 text-white font-bold sm:text-3xl lg:text-5xl lg:w-2/3 lg:left-20 z-10">{selectedSolutions.title}</h1>
                <div className="absolute top-0 bottom-0 right-0 left-0 bg-secondary/60" />
            </div>
            <div className="sm:block lg:hidden my-4 font-bold p-2 rounded shadow-md">
                <h1 className="flex justify-start items-center text-sm">
                    <a href="/">HOME</a>
                    <FaChevronRight className="mx-2" />
                    <span className="text-primary">{selectedSolutions.title}</span>
                    </h1>
                </div>
            <div className="my-20 grid sm:grid-cols-1 lg:grid-cols-2 w-11/12 mx-auto">
                <div style={{
                    backgroundImage: `url(${Challenge})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain'
                }}
                    className="lg:h-[400px] sm:h-[250px] sm:w-full"
                />
                <div className="lg:flex items-center sm:text-center sm:block">
                    {/* <h1 className="lg:text-5xl sm:text-4xl font-bold text-secondary mb-10 lg:hidden sm:block">Challenge</h1> */}
                    <p className="sm:w-full lg:w-11/12 sm:text-lg sm:text-start lg:text-2xl font-medium sm:mt-4">{selectedSolutions.challenge}</p>
                </div>
            </div>
            <section>
                <div style={{ backgroundImage: `url(${Ptbg})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} className="lg:py-10  flex items-center justify-center">
                    <h1 className="lg:text-5xl font-bold text-secondary mb-10 lg:mt-10 sm:text-3xl">Our Solution</h1>
                </div>
                <div className="sm:w-11/12 sm:px-1 lg:w-9/12 mx-auto font-medium">
                    {selectedSolutions.solutions.map(p => (
                        <p className="lg:my-6 sm:my-4 font-medium sm:text-lg lg:text-xl">{p}</p>
                    ))}
                </div>
            </section>
            <section className="mt-20 mb-10">
                <div className="w-9/12 mx-auto mb-10">
                    <h1 className="font-bold my-6 lg:text-5xl lg:my-10 sm:text-3xl text-secondary lg:text-start sm:text-center">Our Brands</h1>
                </div>
                <br/>
                <div className="flex sm:justify-center lg:justify-start items-center sm:w-full lg:w-9/12 sm:flex-wrap mx-auto">
                    {selectedSolutions.brands.map(img => (
                        <img key={img} src={img} alt="brands" className="w-32 m-4" />
                    ))}
                </div>
                <br/>
                <div className="flex justify-center items-center mt-10 sm:py-4 lg:py-6">
                    <a href={RoutePaths.CONTACT} className="bg-primary text-white rounded py-4 px-8 flex items-center hover:scale-105 shadow">GET IN TOUCH <FaArrowRight className="ml-2" /> </a>
                </div>
            </section>
            


        </>
    )
}
