import React, { useState, useEffect } from "react";
import Logo from "../../assets/svg/logo.svg";
import {
  FaPhoneSquareAlt,
  FaRegEnvelope,
  FaFacebookSquare,
  FaTwitterSquare,
  FaLinkedin,
  FaAngleDown,
  FaBars,
  FaTimesCircle,
  FaChevronDown,
  FaChevronUp,
  FaChevronRight
} from "react-icons/fa";
import Pattern from '../../assets/img/pattern.png';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from "../../utils/constant";
import {motion} from 'framer-motion';

const Links = [
  {
    name: 'HOME',
    url: RoutePaths.HOME,
    active: false
  },
  {
    name: 'SOLUTIONS',
    url: '',
    active: false
  },
  {
    name: 'CONTACT US',
    url: RoutePaths.CONTACT,
    active: false
  },
];
const solutions = [
  {
    name: 'DATA COLLECTION'
  },
  {
    name: 'DATA BACKUP,STORAGE AND DISASTER RECOVERY'
  },
  {
    name: 'CYBER SECURITY AND DATA SECURITY'
  },
  {
    name: 'CUTTING EDGE BIG DATA AND ANALYTICS'
  },
  {
    name: 'PROFESSIONAL & CONSULTANCY SERVICES'
  }
]

export default function Header() {
  const [showDropMenu, setShowDropMenu] = useState(false);
  const [showDropDownOptions, setShowDropDownOptions] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname;

  Links.forEach(link => {
    if (link.url === currentRoute) {
      link.active = true;
    }
  });

  const hideDropMenu = () => {
    setTimeout(() => {
      setShowDropMenu(false);
    }, 100)
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };


    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);



  return (
    <>
      <header className={`fixed flex lg:flex-row items-center justify-between bg-white z-30 left-0 right-0 py-2 ${isSticky ? 'bg-sticky' : ''}`}
        style={{
          borderBottom: '6px solid',
          borderImageSource: `url(${Pattern})`,
          borderImageSlice: 20
        }}

      >
        <a href="/" alt="Eits Logo"><img src={Logo} alt="eITs" className="lg:ml-10 sm:ml-2 sm:w-34" /></a>
        <div className="mr-14 sm:hidden lg:flex flex-col items-end justify-center">
          <div className="flex flex-row w-full mb-2">
            <div className="flex items-center justify-center mr-4">
              <span className="p-2 bg-primary rounded-full hover:scale-105 cursor-pointer">
                <FaPhoneSquareAlt className="text-white" />
              </span>
              <h1 className="text-sm my-1 ml-2">Phone Number <br /> +254 750 211 117</h1>
            </div>
            <div className="flex items-center justify-center mr-4">
              <span className="p-2 bg-primary rounded-full hover:scale-105 cursor-pointer">
                <FaRegEnvelope className="text-white" />
              </span>
              <h1 className="text-sm my-1 ml-2">Contact Email<br />info@eits.co.ke</h1>
            </div>
            <div className="flex items-center justify-center mr-4 ml-auto">
              <span className="p-2 bg-primary rounded-full hover:scale-105 cursor-pointer">
                <FaFacebookSquare className="text-white" />
              </span>
            </div>
            <div className="flex items-center justify-center mr-4">
              <span className="p-2 bg-primary rounded-full hover:scale-105 cursor-pointer">
                <FaTwitterSquare className="text-white" />
              </span>
            </div>
            <div className="flex items-center justify-center">
              <span className="p-2 bg-primary rounded-full hover:scale-105 cursor-pointer">
                <FaLinkedin className="text-white" />
              </span>
            </div>

          </div>
          <nav className="mt-4 flex">
            {Links.map((link) => {
              if (link.url === '') {
                return (

                  <a href={link.url} className={`mx-4 no-underline uppercase font-bold flex flex-row items-center hover:text-primary ${link.active ? 'text-primary' : 'text-black'}`} onMouseEnter={() => setShowDropMenu(true)}>
                    {link.name}
                    <FaAngleDown />
                  </a>

                )
              } else {
                return (
                  <a href={link.url} className={`mx-4 no-underline uppercase font-bold flex flex-row items-center hover:text-primary ${link.active ? 'text-primary' : 'text-black'}`}>
                    {link.name}
                  </a>
                )
              }
            })}

          </nav>
        </div>
        <div className={` ${showDropMenu ? 'flex' : 'hidden'} z-50 absolute top-[94%] right-[12%] flex-col font-bold p-6 bg-white transition-all delay-150 ease-in-out rounded drop-shadow-lg `} onMouseEnter={() => setShowDropMenu(true)} onMouseLeave={hideDropMenu}>
          {solutions.map(lnk => (
            <a href={`/solutions/${lnk.name}`} className="hover:text-primary my-1 transition-all delay-75 ease-in-out hover:scale-95">{lnk.name}</a>
          ))}
        </div>
        <div className="lg:hidden sm:flex mr-4">
          <FaBars className="text-3xl text-primary" onClick={() => setMobileMenuOpen(true)} />
        </div>

      </header>
      {mobileMenuOpen &&
        <div className="bg-black text-white fixed z-40 top-0 bottom-0 left-0 right-0 transition-opacity duration-300 ease-in-out">
          <div className="my-6 flex justify-end mr-6">
            <FaTimesCircle className="text-3xl text-white" onClick={() => setMobileMenuOpen(false)} />
          </div>
          <ul style={{marginTop:'10%'}}  className="text-white flex justify-center h-2/3 items-center flex-col w-10/12 mx-auto">
            {Links.map((link) => {
              if (link.url === '') {
                return (
                  <li className="my-4 flex items-center justify-between flex-col w-11/12">
                    <span className={`px-4 w-full flex items-center justify-between  no-underline uppercase font-bold hover:text-primary hover:underline ${link.active ? 'text-primary' : 'text-white'}   `} onClick={() => setShowDropDownOptions(!showDropDownOptions)}>
                      {link.name}{ showDropDownOptions ? <FaChevronUp className="" /> : <FaChevronDown className=""/> }  
                    </span>
                    {showDropDownOptions &&
                      <motion.div
                      initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
            transition={{ duration: 0.6, ease: 'easeInOut' }}
                       className="p-2 text-tertiary w-10/12 mx-auto transition-opacity duration-100 ease-in-out delay-75">
                        {solutions.map(lnk => (
                          <div className="mb-2 text-md">
                          <a href={`/solutions/${lnk.name}`} className="text-primary/80 transition-all delay-75 ease-in-out hover:scale-95">{lnk.name}</a>
                          </div>
                        ))}
                      </motion.div>
                    }
                  </li>
                )
              } else return (
                <li className="my-4 w-11/12">
                  <a href={link.url} className={`mx-4 no-underline uppercase font-bold hover:text-primary hover:underline flex items-center justify-between ${link.active ? 'text-primary' : 'text-white'}`}>
                    {link.name}
                    <FaChevronRight />
                  </a>
                </li>
              )
            }
            )}
          </ul>
        </div>
      }
    </>
  );
}
